import _ from 'lodash';
import './../sass/main.scss';

window.$ = window.jQuery = require('jquery');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

require ('./typographer/jquery.typographer.min.pack.js');
require ('./typographer/hyph-pl.min.js');

require ('../../node_modules/slicknav/jquery.slicknav.js');
import 'jquery-validation';

jQuery.noConflict();

jQuery(document).ready(function(){
	
	jQuery('body img').addClass('img-fluid');
	
	jQuery('body').typographer_orphan({
	  forbidden: ["a", "i", "o", "u", "w", "z", "oraz", "dla"]
	});
	
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
	  return new bootstrap.Popover(popoverTriggerEl)
	});
	
	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
	  return new bootstrap.Tooltip(tooltipTriggerEl)
	});
	
	//Slider Top START
	
	const sliderHeaderEl = document.querySelector('#slider-header');
	const carouselTop = new bootstrap.Carousel(sliderHeaderEl, {
	    ride: "carousel",
		pause: false
	});
	
	//Slider Top STOP
	
	//Slicknav START

	jQuery("#top-bar > ul").slicknav({
		appendTo: '#top-bar',
		label: '',
		closeOnClick: true,
		allowParentLinks: true,
	});

	//Slicknav STOP
	
	//Button toggle START
	
	var mbtnTgState = false;
	jQuery('.main-btn-toggle').parent().find('.main-content-toggle').hide();
	jQuery('.main-btn-toggle').click(function() {
		jQuery(this).parent().find('.main-content-toggle').slideToggle('slow');
		if(!mbtnTgState) {
			mbtnTgState = true;
			if(endLang == 'pl') jQuery(this).html('zwiń &nbsp;<i class="bi bi-chevron-up"></i>');
			else jQuery(this).html('less &nbsp;<i class="bi bi-chevron-up"></i>');
		} else {
			mbtnTgState = false;
			if(endLang == 'pl') jQuery(this).html('rozwiń &nbsp;<i class="bi bi-chevron-down"></i>');
			else jQuery(this).html('more &nbsp;<i class="bi bi-chevron-down"></i>');
		}
	});
	
	//Button toggle STOP
	
	const carouselOneEl = document.querySelector('#carousel-one');
	const carouselOne = new bootstrap.Carousel(carouselOneEl, {});
	jQuery('#main-btn-crl-next').click(function() {
		carouselOne.next();
	});
	
	stickyBar();
	
	jQuery('#main-logo-link').click(function() {
		jQuery(window).scrollTop(0);
	});
	
	if(endLang == 'pl') {
		jQuery("#main-contact-form").validate({
			rules: {
				name: {
					required: true,
				},
				email: {
					required: true,
					email: true
				},
				message: {
					required: true,
				},
				data_reg: {
					required: true
				}
			},
			messages: {
				name: {
					 required: "Imię i nazwisko jest wymagane!",
				},
				email: {
					required: "E-Mail jest wymagany!",
					email: "E-Mail jest nieprawidłowy!"
				},
				message: {
					required: "Wiadomość jest wymagana!",
				},
				data_reg: {
					required: "Musisz zaakceptować politykę prywatności!"
				}
			}
		});
	} else {
		jQuery("#main-contact-form").validate({
			rules: {
				name: {
					required: true,
				},
				email: {
					required: true,
					email: true
				},
				message: {
					required: true,
				},
				data_reg: {
					required: true
				}
			},
			messages: {
				name: {
					 required: "Full name is required!",
				},
				email: {
					required: "E-Mail is required!",
					email: "E-mail is invalid!"
				},
				message: {
					required: "Message is required!",
				},
				data_reg: {
					required: "You must accept the privacy policy!"
				}
			}
		});
	}
	
	jQuery('#modal-send-form').modal('show');
	
	mainResize();
	
});

window.onload = function () {
	jQuery('#main-loader').remove();
};

jQuery(window).resize(function() {
	mainResize();
});


//Funkcje

function mainResize() {
	var wwidth = jQuery(window).width();
    if (wwidth <= 767) {
		jQuery('#slider-header .carousel-item').height('100vh');
	} else {
		jQuery('#slider-header .carousel-item').height(990);
		if(wwidth > 1920) {
			setSliderTopHeight();
		}
	}
	setVideoOne(wwidth);
	setContainerVideo(wwidth);
}

function placementObjectBefore(element, elementBefore) {
	jQuery(elementBefore).before(jQuery(element));
}

function placementObjectAfter(element, elementAfter) {
	jQuery(elementAfter).after(jQuery(element));
}

function placementObjectAppend(element, elementAppend) {
	jQuery(elementAppend).append(jQuery(element));
}

function placementObjectPrepend(element, elementPrepend) {
	jQuery(elementPrepend).prepend(jQuery(element));
}

function setSliderTopHeight(factor = 0) {
	var headerWidth = jQuery('#main-header').width();
	if(!factor) jQuery('#slider-header .carousel-item').height(headerWidth*.515);
	else jQuery('#slider-header .carousel-item').height(headerWidth*factor);
}

function stickyBar() {
	jQuery(window).scroll(function() {
		var stickyNavTop = 140;
		var mainScrollTop = jQuery(window).scrollTop(); // our current vertical position from the top

		// if we've scrolled more than the navigation, change its position to fixed to stick to top,
		// otherwise change it back to relative
		if (mainScrollTop > stickyNavTop) { 
			jQuery('#top-bar').addClass('top-bar-sticky');
		} else {
			jQuery('#top-bar').removeClass('top-bar-sticky'); 
		}
	});
}

function setVideoOne(wwidth) {
	var parentVO = jQuery('#video-one').parent();
	if(wwidth < 1201) {
		setTimeout(function() {
			jQuery('#video-one').attr('width', parentVO.width());
			jQuery('#video-one').attr('height', (parentVO.width*0.698));
		}, 1000);
	} else {
		jQuery('#video-one').attr('width', '100%');
		jQuery('#video-one').attr('height', 839);
	}
}

function setContainerVideo(wwidth) {
	if(wwidth < 768) {
		placementObjectPrepend('#wrap-video-one', '#row-video-one');
	} else {
		placementObjectAppend('#wrap-video-one', '#row-video-one');
	}
}